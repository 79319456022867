<template>
    <div class="container">
        <crud ref="crud" :options="options">
            <template slot="header">
                <el-form-item>
                    <el-input v-model="options.params.keyword" clearable placeholder="站点名称"/>
                </el-form-item>
                <el-form-item>
                    <el-button @click="$refs.crud.getLists()" icon="el-icon-search">查找</el-button>
                </el-form-item>
            </template>
        </crud>
    </div>
</template>

<script>
export default {
    name: "List",
    data: function () {
        return {
            options: {
                addBtn: true,
                editBtn: true,
                delBtn: true,
                pager: true,
                addUrl: 'league.Site.add',
                addPerm: 'league_Site_add',
                editUrl: 'league.Site.edit',
                editPerm: 'league_Site_edit',
                delUrl: 'league.Site.delete',
                delPerm: 'league_Site_delete',
                listUrl: 'league.Site.page',
                formLabelWidth: '100px',
                params: {
                    keyword: null
                },
                columns: [
                    {prop: "name", label: "站点名称", required: true},
                    {prop: "code", label: "站点编码"},
                    {prop: "service", label: "手续费率", type: 'number', required: true},
                    {prop: "balance", label: "站点余额"},
                    {prop: "sort", label: "排序", type: "number", value: 0},
                    {prop: "remark", label: "备注",}
                ],
            }
        }
    },
    methods: {}
}
</script>

<style scoped>

</style>
